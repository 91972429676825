
import Box from "@mui/material/Box";

const BodyContainer = ({ headerHeight, children }) => {
  return (
    <Box sx={{
      height: 'auto',
      minHeight: '85vh',
      width: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: `${headerHeight}px`
    }}>
      {children}
    </Box>
  )
};

export default BodyContainer;
