import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Home from './pages/Home.jsx';
import Header from './components/Header.jsx';
import Footer from './components/Footer.jsx';
import About from './pages/About.jsx';
import FrequentlyAskedQuestions from './pages/FrequentlyAskedQuestions.jsx';
import Promo from './pages/Promo.jsx';
import Contact from './pages/Contact.jsx';
import BodyContainer from './components/BodyContainer.jsx';
import ScrollToTop from './components/ScrollToTop.jsx';
import ApplyThroughUs from './pages/ApplyThroughUs.jsx';
import ApplyDirectly from './pages/ApplyDirectly.jsx';

const PageList = () => {
  const headerRef = React.useRef(null);
  const [headerHeight, setHeaderHeight] = React.useState(0);

  const updateHeaderHeight = () => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  };

  React.useEffect(() => {
    updateHeaderHeight();
    window.addEventListener('resize', updateHeaderHeight);

    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, [headerRef]);

  return (
    <>
      <Header ref={headerRef} />
      <BodyContainer headerHeight={headerHeight} >
        <Routes>
          <Route path='/home' element={<Home />} />
          <Route path='/' element={<Navigate to='/home' />} />
          <Route path='/about' element={<About />} />
          <Route path='/faq' element={<FrequentlyAskedQuestions />} />
          <Route path='/promo' element={<Promo />} />
          <Route path='/apply/unicamp' element={<ApplyThroughUs />} />
          <Route path='/apply/direct' element={<ApplyDirectly />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
      </BodyContainer>
      <Footer />
      <ScrollToTop />
    </>
  );
};
  
export default PageList;
