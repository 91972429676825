import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';

import PageList from './PageList';

const App = () => {
  return (
    <Router>
      <PageList />
    </Router>
  );
}

export default App;
