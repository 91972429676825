import React from 'react';
import axios from 'axios';

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ContactFormBox from "./ContactFormBox";
import { styled } from '@mui/material/styles';


const StyledButton = styled(Button)({
  backgroundColor: 'black',
  color: 'white',
  fontSize: '1rem',
  fontWeight: 'bold',
  borderTopLeftRadius: '25px',
  borderTopRightRadius: '25px',
  borderBottomLeftRadius: '25px',
  borderBottomRightRadius: '25px',
  marginTop: '5%',
  marginBottom: '5%'
});


const ContactForm = (props) => {
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [number, setNumber] = React.useState('');
  const [wechat, setWechat] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [message, setMessage] = React.useState('');

  const [emailError, setEmailError] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const [numberError, setNumberError] = React.useState('');
  const [countryError, setCountryError] = React.useState('');

  const checkValid = () => {
    const validEmail = /^[\w-.]+@[\w-.]+$/;
    if (!validEmail.test(email)) {
      setEmailError('Invalid Email');
      return false;
    }
    setEmailError('');

    if (name === '') {
      setNameError('Name is required');
      return false;
    }
    setNameError('');

    if (number === '') {
      setNumberError('Phone Number is required');
      return false;
    }
    setNumberError('');

    if (country === '') {
      setCountryError('Country of Residence is required');
      return false;
    }
    setCountryError('');

    return true;
  }
  const submit = async () => {
    if (!checkValid()) {
      return;
    } else {
      const template = {
        email: email,
        name: name,
        number: number,
        wechat: wechat,
        country: country,
        message: message
      };
  
      const template_id = message === '' ? 'template_52jcaet' : 'template_3j2vmsd';
      const body = {
        service_id: 'service_b68k289',
        template_id: template_id,
        user_id: 'Og-6bZZmoP7Xfx1ka',
        template_params: template
      }
      
      props.setSubmitted(true);

      await axios.post('https://api.emailjs.com/api/v1.0/email/send', body);
    }
  };
  
  return (
    <Box sx={{
      height: '100%',
      width: '100%',
      backgroundColor: '#bc945d',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly'
    }}>

      <ContactFormBox text='Email' value={email} setter={setEmail} required errorMessage={emailError} />
      <ContactFormBox text='Your Name' value={name} setter={setName} required errorMessage={nameError} />
      <ContactFormBox text='Phone Number' value={number} setter={setNumber} required number errorMessage={numberError} />
      <ContactFormBox text='Your WeChat ID (if applicable)' value={wechat} setter={setWechat} errorMessage='' />
      <ContactFormBox text='Country of Residence' value={country} setter={setCountry} required errorMessage={countryError} country />
      <ContactFormBox text='Message (optional)' value={message} setter={setMessage} errorMessage='' />

      <StyledButton onClick={submit}>
        GET IN TOUCH
      </StyledButton>
    </Box>
  )
};

export default ContactForm;
