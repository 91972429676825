import React from 'react';
import { useMediaQuery } from 'react-responsive';

import Box from "@mui/material/Box";
import ContactSubmitted from "../components/ContactSubmitted";
import ContactForm from '../components/ContactForm';


const Contact = () => {
  const [submitted, setSubmitted] = React.useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  return (
    <Box sx={{
      height: 'auto',
      width: isMobile ? '70%' : '50%',
      paddingTop: '2%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      marginBottom: '5%'
    }}>
      { 
        submitted ?
        <ContactSubmitted />
        :
        <ContactForm setSubmitted={setSubmitted} />
      }
    </Box>
  )
};
  
export default Contact;
  