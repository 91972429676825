import React from 'react'
import Select from 'react-select';
import countryList from 'react-select-country-list'


import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


const ContactFormBox = (props) => {
  const [value, setValue] = React.useState('');
  const validNumber = /^\+?[0-9 ]*$/;
  const countries = countryList();
  countries.setLabel('TW', 'Taiwan');

  const options = React.useMemo(() => countryList().getData(), [])

  const handleChange = (event) => {
    const value = event.target.value

    if (props.number) {
      if (value !== '' && !validNumber.test(value)) {
        return;
      }
    }
    if (props.number && !validNumber.test(event.key)) {
      event.preventDefault();
    }

    props.setter(value);
  };

  const selectHandler = value => {
    setValue(value);
    props.setter(value.label);
  };

  return (
    <Box sx={{
      width: '80%',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '1vmin',
      marginTop: '3%',
      padding: '5%'
    }}>
      <Typography>
        {props.text} {props.required && <span style={{ color: '#c8494d', fontWeight: 'bold' }}>*</span>}
      </Typography>
      {
        props.country 
        ?
        <Select
          options={options}
          value={value}
          onChange={selectHandler}
          styles={{
            control: (provided) => ({
            ...provided,
            marginTop: '5%',
            borderColor: props.errorMessage !== '' ? 'red' : provided.borderColor,
            '&:hover': { borderColor: props.errorMessage !== '' ? 'red' : provided.borderColor }
          }), }}
        />
        :
        <TextField
          label="Your answer"
          variant="standard"
          value={props.value}
          sx={{ marginTop: '5%' }}
          multiline
          onChange={handleChange}
          error = {props.errorMessage !== ''}
          helperText = {props.errorMessage}
          required={props.required}
        />
      }
    </Box>
  )
};

export default ContactFormBox;
