import React from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const createData = (heading, col1, col2) => {
    return { heading, col1, col2 };
  }
  
const tableComparisonData = [
  createData('Focus', 'Academic enrichment and skill development',
              'Recreational activities and personal development'),
  createData('Content', 'Structured courses in various academic subjects',
              'Diverse activities (sports, games, sightseeing, etc.)'),
  createData('Participants', 'Students seeking academic advancement or exploration',
              'Children and teenagers looking for fun and social interaction'),
  createData('Learning Environment', 'Held in world-leading universities, learning from expert tutors from these institutions',
              'Locations are not necessarily universities; can also be held in other suitable venues'),
  createData('Outcome', 'Better prepared for university, with opportunities to find inspiration in their academic pursuits',
              'New experiences, friendships, and personal growth'),
];

const StyledTableCell = styled(TableCell)({
  border: '1px black solid'
});

const ComparisonMobile = () => {
  const [index, setIndex] = React.useState(1);

  return (
    <TableContainer sx={{ width: '100%' }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: index === 1 ? 'flex-end' : 'flex-start',
        marginTop: '5%'
      }}>
        {
          index === 2 &&
          <Button
            onClick={() => setIndex(1)}
            startIcon={<KeyboardDoubleArrowLeftIcon sx={{ border: '1px black solid', color: 'black' }} />}
            hidden={index === 1}
          />
        }
        {
          index === 1 &&
          <Button
            onClick={() => setIndex(2)}
            startIcon={<KeyboardDoubleArrowRightIcon sx={{ border: '1px black solid', color: 'black' }} />}
          />
        }
      </Box>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            {
              index === 1 &&
              <StyledTableCell sx={{ fontWeight: 'bold' }} align='left'>Academic Summer School</StyledTableCell>
            }
            {
              index === 2 &&
              <StyledTableCell sx={{ fontWeight: 'bold' }} align='left'>Summer Camp</StyledTableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {tableComparisonData.map((row) => (
            <TableRow
              key={row.heading}
            >
              <StyledTableCell align='center' component='th' scope='row' sx={{ fontWeight: 'bold', padding: 0 }}>
                {row.heading}
              </StyledTableCell>
              {
                index === 1 &&
                <StyledTableCell align='left'>{row.col1}</StyledTableCell>
              }
              {
                index === 2 &&
                <StyledTableCell align='left'>{row.col2}</StyledTableCell>
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
  
export default ComparisonMobile;
