import React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const DrawerList = ({toggleDrawer}) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(false);
  const [australia, setAustralia] = React.useState(false);
  const [canada, setCanada] = React.useState(false);
  const [japan, setJapan] = React.useState(false);
  const [singapore, setSingapore] = React.useState(false);
  const [uk, setUk] = React.useState(false);
  const [usa, setUsa] = React.useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  }

  const handleAustralia = () => {
    setAustralia(!australia);
  }

  const handleCanada = () => {
    setCanada(!canada);
  }

  const handleJapan = () => {
    setJapan(!japan);
  }

  const handleSingapore = () => {
    setSingapore(!singapore);
  }

  const handleUk = () => {
    setUk(!uk);
  }

  const handleUsa = () => {
    setUsa(!usa);
  }

  return (
    <Box sx={{
      width: '200px',
    }}>
      <List>
        <ListItem disablePadding onClick={toggleDrawer(false)}>
          <ListItemButton onClick={() => navigate('/home')}>
            <ListItemText>Home</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding onClick={toggleDrawer(false)}>
          <ListItemButton onClick={() => navigate('/about')}>
            <ListItemText>About</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding onClick={toggleDrawer(false)}>
          <ListItemButton onClick={() => navigate('/faq')}>
            <ListItemText>FAQ</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItemButton onClick={handleClick}>
          <ListItemText>Programs</ListItemText>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List disablePadding>

            <ListItemButton sx={{ pl: 6 }} onClick={handleAustralia}>
              <ListItemText>Australia</ListItemText>
              {australia ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={australia} timeout="auto" unmountOnExit>
              <List disablePadding>
                <ListItem disablePadding>
                  <ListItemButton sx={{ pl: 12 }} onClick={() => window.location.href = 'https://www.immerse.education/sydney-summer-school/'}>
                    <ListItemText>Sydney</ListItemText>
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
        
            <ListItemButton sx={{ pl: 6 }} onClick={handleCanada}>
              <ListItemText>Canada</ListItemText>
              {canada ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={canada} timeout="auto" unmountOnExit>
              <List disablePadding>
                <ListItem disablePadding>
                  <ListItemButton sx={{ pl: 12 }} onClick={() => window.location.href = 'https://www.immerse.education/toronto-summer-school/'}>
                    <ListItemText>Toronto</ListItemText>
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
            
            <ListItemButton sx={{ pl: 6 }} onClick={handleJapan}>
              <ListItemText>Japan</ListItemText>
              {japan ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={japan} timeout="auto" unmountOnExit>
              <List disablePadding>
                <ListItem disablePadding>
                  <ListItemButton sx={{ pl: 12 }} onClick={() => window.location.href = 'https://www.immerse.education/tokyo-summer-school/'}>
                    <ListItemText>Tokyo</ListItemText>
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
            
            <ListItemButton sx={{ pl: 6 }} onClick={handleSingapore}>
              <ListItemText>Singapore</ListItemText>
              {singapore ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={singapore} timeout="auto" unmountOnExit>
              <List disablePadding>
                <ListItem disablePadding>
                  <ListItemButton sx={{ pl: 12 }} onClick={() => window.location.href = 'https://www.immerse.education/singapore-summer-school/'}>
                    <ListItemText>Singapore</ListItemText>
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
            
            <ListItemButton sx={{ pl: 6 }} onClick={handleUk}>
              <ListItemText>UK</ListItemText>
              {uk ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={uk} timeout="auto" unmountOnExit>
              <List disablePadding>
                <ListItem disablePadding>
                  <ListItemButton sx={{ pl: 12 }} onClick={() => window.location.href = 'https://www.immerse.education/oxford-summer-school/'}>
                    <ListItemText>Oxford</ListItemText>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton sx={{ pl: 12 }} onClick={() => window.location.href = 'https://www.immerse.education/cambridge-summer-school/'}>
                    <ListItemText>Cambridge</ListItemText>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton sx={{ pl: 12 }} onClick={() => window.location.href = 'https://www.immerse.education/london-summer-school/'}>
                    <ListItemText>UCL</ListItemText>
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>

            <ListItemButton sx={{ pl: 6 }} onClick={handleUsa}>
              <ListItemText>USA</ListItemText>
              {usa ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={usa} timeout="auto" unmountOnExit>
              <List disablePadding>
                <ListItem disablePadding>
                  <ListItemButton sx={{ pl: 12 }} onClick={() => window.location.href = 'https://www.immerse.education/new-york-summer-school/'}>
                    <ListItemText>Columbia University</ListItemText>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton sx={{ pl: 12 }} onClick={() => window.location.href = 'https://www.immerse.education/san-francisco-summer-school/'}>
                    <ListItemText>UC Berkeley</ListItemText>
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Collapse>
      </List>
      <Divider />
      <List>
          <ListItem disablePadding onClick={toggleDrawer(false)}>
            <ListItemButton onClick={() => navigate('/promo')}>
              <ListItemText>Promo Code</ListItemText>
            </ListItemButton>
          </ListItem>
      </List>
      <Divider />
      <List>
          <ListItem disablePadding onClick={toggleDrawer(false)}>
            <ListItemButton onClick={() => navigate('/contact')}>
              <ListItemText>Contact</ListItemText>
            </ListItemButton>
          </ListItem>
      </List>
    </Box>
  );
}

export default DrawerList;
