import React from 'react';
import { useMediaQuery } from 'react-responsive';

import ComparisonMobile from '../components/responsiveness/ComparisonMobile';
import ComparisonDesktop from '../components/responsiveness/ComparisonDesktop';


const ComparisonTable = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  return (
    <>
      {
        isMobile &&
        <ComparisonMobile />
      }
      {
        !isMobile &&
        <ComparisonDesktop />
      }
    </>
  );
}
  
export default ComparisonTable;
  