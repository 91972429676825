import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Logo from '../assets/finalLogo.jpg';
import NavDrawer from './NavDrawer';

const Header = React.forwardRef((props, ref) => {
  const[widths, setWidths] = React.useState(['5%', '9%']);
  
  const isDesktop = useMediaQuery({ query: '(min-width: 1224px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  
  React.useEffect(() => {
    if (isDesktop) {
      setWidths(['5%', '9%']);
    } else if (isMobile) {
      setWidths(['20%', '25%']);
    } else {
      setWidths(['10%', '18%']);
    }
  }, [isDesktop, isMobile]);
  
  return (
    <Box ref={ref} sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1000
    }}>
      <Box sx={{
        height: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#bc945d',
        color: 'white'
      }}>
        <Box sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Typography sx={{
            fontSize: { xs: '0.7rem', sm: '0.8rem', md: '1rem' },
            whiteSpace: 'normal',
            textAlign: 'center'
          }}>
            Use our exclusive <Link to='/promo' style={{ color: 'black', cursor: 'pointer', fontWeight: 'bold' }}> 
              PROMO Code 
            </Link> to unlock a generous cashback reward. Enjoy our premium <Link to='/promo' style={{ color: 'black', cursor: 'pointer', fontWeight: 'bold' }}> 
              Early Bird Bonus 
            </Link> if you apply before 30 Nov 2024.
          </Typography>
          <Typography sx={{
            fontSize: { xs: '0.7rem', sm: '0.8rem', md: '1rem' },
            whiteSpace: 'normal',
            textAlign: 'center'
          }}>
            Secure your spot and enjoy double rewards today!
          </Typography>
        </Box>
      </Box>
      <Box sx={{
        height: 'auto',
        width: '100%',
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: '1%',
        paddingBottom: '1%'
      }}>
        <Box sx={{
          height: 'auto',
          width: isDesktop ? '80%' : '60%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}> 

          <Box sx={{
            height: '100%',
            width: widths[0],
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <NavDrawer />
          </Box>

          <Box sx={{
            height: '100%',
            width: widths[1],
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Link to='/home' style={{ cursor: 'pointer' }}>
              <img
                src={Logo}
                alt='Immerse Logo'
                draggable={false}
                width={'100%'}
              />
            </Link>
          </Box>
        </Box>
        <Box sx={{
          height: 'auto',
          width: isDesktop ? '20%' : '40%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          fontSize: isDesktop ? '1rem' : '0.8rem'
        }}>
          <Link to='/about' style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none' }}> 
              About
          </Link>
          <Link to='/contact' style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none' }}> 
              Contact
          </Link>
        </Box>
      </Box>
    </Box>
  )
});

export default Header;
