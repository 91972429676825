import React from 'react';
import { useMediaQuery } from 'react-responsive';

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuIcon from '@mui/icons-material/Menu';
import DrawerList from './DrawerList';

const NavDrawer = () => {
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };



  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly'
    }}>
      {
        isMobile &&
        <MenuIcon onClick={toggleDrawer(true)} style={{ fontSize: '1rem', color: 'white', cursor: 'pointer' }}/>
      }
      {
        !isMobile &&
        <MenuIcon onClick={toggleDrawer(true)} style={{ fontSize: '2rem', color: 'white', cursor: 'pointer' }}/>
      }
      <SwipeableDrawer open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)} >
        <DrawerList toggleDrawer={toggleDrawer} />
      </SwipeableDrawer>
    </Box>
  );
}

export default NavDrawer;
