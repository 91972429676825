import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

const theme = createTheme({
  typography: {
    body1: {
      lineHeight: '2rem',
      marginTop: '1%',
      textAlign: 'justify',
      fontSize: '1rem'
    },
    body2: {
      lineHeight: '2rem',
      marginTop: '5%',
      textAlign: 'justify',
      fontSize: '1rem'
    }
  },
});

const ApplyThroughUs = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        height: 'auto',
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '2%',
        marginBottom: '5%'
      }}>
        <Typography variant='h4'>Apply through us</Typography>
        <Typography variant={isMobile ? 'body2' : 'body1'} >
          This is the simplest and easiest way, and it&#39;s completely free!
        </Typography>

        <List sx={{
          listStyleType: 'decimal',
          listStylePosition: 'outside',
          ml: 5,
          fontSize: '1rem'
        }}>
          <ListItem sx={{ display: 'list-item' }}>
            <Link to='/contact'
              style={{
                cursor: 'pointer',
                color: '#3c95d3'
              }}
            >
              Contact us
            </Link>
          </ListItem>

          <ListItem sx={{ display: 'list-item' }}>
            Our counsellor will reach out to you as soon as possible and assist you from
            there.
          </ListItem>
        </List>
      </Box>
    </ThemeProvider>
  )
};

export default ApplyThroughUs;
