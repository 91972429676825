import { NavLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Berkeley from '../assets/unilogos/Berkeley.jpg'
import Cambridge from '../assets/unilogos/Cambridge.jpg'
import Columbia from '../assets/unilogos/Columbia.jpg'
import NUS from '../assets/unilogos/NUS.jpg'
import Oxford from '../assets/unilogos/Oxford.jpg'
import Toronto from '../assets/unilogos/Toronto.jpg'
import UCL from '../assets/unilogos/UCL.jpg'
import USyd from '../assets/unilogos/USyd.jpg'
import WeChat from '../assets/WeChat.jpg'
import WhatsApp from '../assets/WhatsApp.jpg'

const Footer = () => {
  return (
    <Box sx={{
      height: '100%',
      width: '100%',
      backgroundColor: 'black',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between'
    }}>
      <Box sx={{
        height: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        backgroundColor: '#efeee8',
        color: 'white',
        overflow: 'hidden'
      }}>
        <img src={Oxford} alt='Oxford Logo' width='10%'/>
        <img src={Cambridge} alt='Cambridge Logo' width='10%'/>
        <img src={UCL} alt='UCL Logo' width='10%'/>
        <img src={USyd} alt='USyd Logo' width='10%'/>
        <img src={Columbia} alt='Columbia Logo' width='10%'/>
        <img src={Berkeley} alt='Berkeley Logo' width='10%'/>
        <img src={Toronto} alt='Toronto Logo' width='10%'/>
        <img src={NUS} alt='NUS Logo' width='10%'/>
      </Box>
      <Box sx={{
        height: 'auto',
        width: '90%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Box sx={{
          height: '80%',
          width: '25%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          paddingTop: '2%',
          paddingBottom: '2%'
        }}>
          <NavLink to='/contact' style={{ textDecoration: 'none', color: 'inherit' }}>
            <Typography fontWeight='bold' fontSize='1rem' >Connect With Us</Typography>
          </NavLink>
          <img src={WeChat} alt='WeChat' width='20%' style={{ marginLeft: '5%', marginRight: '5%' }} />
          <img src={WhatsApp} alt='WhatsApp' width='20%' />
        </Box>
      </Box>
      <Box sx={{
        height: 'auto',
        width: '60%',
        borderTop: '1px white solid',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        paddingTop: '1%',
        paddingBottom: '1%'
      }}>
        <Typography fontSize={'0.8rem'}>
          Copyright © UNICAMP 2024 v1.01
        </Typography>
      </Box>
    </Box>
  )
};

export default Footer;
