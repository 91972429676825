
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";

import HomeGallery from "../components/HomeGallery.jsx";

const StyledLink = styled(Link)({
  color: 'black',
  textDecoration: 'none'
});

const Home = () => {
  return (
    <Box sx={{
      height: 'auto',
      width: '80%',
      paddingTop: '2%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly'
    }}>
      <HomeGallery />
      <Typography fontSize={'0.7rem'} fontWeight={'bold'}>
        <StyledLink href='https://www.immerse.education/boston-summer-school/'>BOSTON</StyledLink> |&nbsp;
        <StyledLink href='https://www.immerse.education/cambridge-summer-school/'>CAMBRIDGE</StyledLink> |&nbsp;
        <StyledLink href='https://www.immerse.education/london-summer-school/'>LONDON</StyledLink> |&nbsp;
        <StyledLink href='https://www.immerse.education/new-york-summer-school/'>NEW YORK</StyledLink> |&nbsp;
        <StyledLink href='https://www.immerse.education/oxford-summer-school/'>OXFORD</StyledLink> |&nbsp;
        <StyledLink href='https://www.immerse.education/san-francisco-summer-school/'>SAN FRANCISCO</StyledLink> |&nbsp;
        <StyledLink href='https://www.immerse.education/singapore-summer-school/'>SINGAPORE</StyledLink> |&nbsp;
        <StyledLink href='https://www.immerse.education/sydney-summer-school/'>SYDNEY</StyledLink> |&nbsp;
        <StyledLink href='https://www.immerse.education/tokyo-summer-school/'>TOKYO</StyledLink> |&nbsp;
        <StyledLink href='https://www.immerse.education/toronto-summer-school/'>TORONTO</StyledLink> |&nbsp;
        <StyledLink href='https://www.immerse.education/online-courses/'>ONLINE COURSES</StyledLink>
      </Typography>
    </Box>
  )
};

export default Home;
