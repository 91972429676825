import { useMediaQuery } from 'react-responsive';

import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import ScreenShot1 from '../assets/ApplyDirectlySS1.png';
import ScreenShot2 from '../assets/ApplyDirectlySS2.png';

const theme = createTheme({
  typography: {
    body1: {
      lineHeight: '2rem',
      marginTop: '1%',
      textAlign: 'justify',
      fontSize: '1rem'
    },
    body2: {
      lineHeight: '2rem',
      marginTop: '5%',
      textAlign: 'justify',
      fontSize: '1rem'
    }
  },
});

const ApplyDirectly = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        height: 'auto',
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '2%',
        marginBottom: '5%'
      }}>
        <Typography variant='h4'>Apply directly on the provider's website using the promo code</Typography>
        <Typography variant={isMobile ? 'body2' : 'body1'} >
          Please follow these steps to ensure you receive any eligible bonuses and rewards.
        </Typography>

        <List sx={{
          listStyleType: 'decimal',
          listStylePosition: 'outside',
          ml: 5,
          fontSize: '1rem'
        }}>
          <ListItem sx={{ display: 'list-item' }}>
            Go to the <a href='https://www.immerse.education/'
              style={{
                cursor: 'pointer',
                color: '#3c95d3',
                textDecoration: 'underline'
              }}
            >
              provider’s website
            </a> and select your program.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Proceed to the online enrolment form.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Select “Agent” and type “Unicamp” in the relevant field, as shown below.
          </ListItem>
          <img src={ScreenShot1} alt='Immerse Form Fields' style={{ width: '50%' }} />
          <ListItem sx={{ display: 'list-item' }}>
            Leave “Discount Code” blank as shown below – Only use this field if you’re
            eligible for alumni, sibling or similar discounts.
          </ListItem>
          <img src={ScreenShot2} alt='Discount Code Field' style={{ width: '25%' }} />
          <ListItem sx={{ display: 'list-item' }}>
            You’re Almost Done – Once you’ve completed the enrolment form, please
            notify us immediately. We’ll add you to our client list and confirm in writing
            whether your application qualifies for the Early Bird Bonus, Special Bonus and
            Cashback Reward.
          </ListItem>
        </List>
      </Box>
    </ThemeProvider>
  )
};

export default ApplyDirectly;
