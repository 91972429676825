import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Collapse from '@mui/material/Collapse';
import RemoveIcon from '@mui/icons-material/Remove';
import Typography from '@mui/material/Typography';

const QATemplate = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  }

  return (
    <>
      <Typography
        variant='h6'
        fontWeight='bold'
        sx={{ 
          mt: 3,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center'
        }}
        onClick={handleClick}
      >
        {expanded ? <RemoveIcon sx={{ marginRight: 2 }} /> : <AddIcon sx={{ marginRight: 2 }} />}
        {props.question}
      </Typography>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {props.children}  
      </Collapse>
    </>
  )
};

export default QATemplate;