import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ComparisonTable from './ComparisonTable';
import QATemplate from '../components/QATemplate';

const theme = createTheme({
  typography: {
    body1: {
      lineHeight: '2rem',
      marginTop: '2%',
      textAlign: 'justify',
      fontSize: '1rem'
    },
    body2: {
      lineHeight: '2rem',
      marginTop: '10%',
      textAlign: 'justify',
      fontSize: '1rem'
    }
  },
});

const FrequentlyAskedQuestions = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        height: 'auto',
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '2%',
        marginBottom: '5%'
      }}>
        <Typography variant='h4'>FAQ</Typography>

        <QATemplate question='1. What is an Academic Summer School?'>
          <Typography variant={isMobile ? 'body2' : 'body1'} >
            An academic summer school is a specialized, structured program designed for
            children and teenagers that focuses on exploring and enhancing their academic skills
            and knowledge in specific subjects. Typically held during the summer at universities
            or colleges, these camps offer participants the chance to immerse themselves in an
            academic environment similar to that of higher education institutions. They provide
            intensive learning experiences that include workshops, lectures, hands-on projects,
            and collaborative activities.
          </Typography>
          <Typography variant={isMobile ? 'body2' : 'body1'} >
            Covering a wide range of subject areas, academic summer schools stimulate
            intellectual curiosity and promote critical thinking. Participants often work with
            experienced instructors or tutors and engage in activities that encourage teamwork
            and problem-solving. In addition to academic learning, these programs also include
            plenty of social activities, allowing students to build friendships and enhance their
            interpersonal skills.
          </Typography>
        </QATemplate>

        <QATemplate question='2. What is an Enrichment Program?'>
          <Typography variant={isMobile ? 'body2' : 'body1'} >
            The online enrichment programs offer students a flexible way to boost their
            academic skills and explore new subjects. With options for group sessions or
            personalized one-on-one research projects, these programs are ideal for students
            looking to grow academically during the school term or for those who can’t attend
            camps in-person. With flexible start dates and durations, participants can dive into
            diverse areas of interest, work alongside expert instructors, and gain valuable
            insights to guide their future university decisions.
          </Typography>
        </QATemplate>

        <QATemplate question='3. Why Attend Academic Summer School?'>
          <Typography variant='subtitle1' fontWeight='bold' sx={{ mt: 2 }} >
            Explore Career Paths:
          </Typography>
          <Typography variant={isMobile ? 'body2' : 'body1'} >
            Many teenagers are uncertain about their interests in specific subjects and may not
            fully understand what those courses involve, even if they have some areas of
            curiosity. Academic summer schools address this by providing students with both
            practical and theoretical experiences that deepen their understanding. Many
            students find that participating in academic summer schools inspire them to discover
            their passions and motivates them to work harder. This early exposure not only
            helps students clarify their interests but also lays a solid foundation for their future
            university course selections and offers valuable insights into potential career paths.
          </Typography>
          <Typography variant={isMobile ? 'body2' : 'body1'}  fontWeight='bold'>
            Additional Benefits:
          </Typography>
          <List
            sx={{
              listStyleType: 'disc',
              listStylePosition: 'outside',
              ml: 5,
              fontSize: '1rem'
            }}>
            <ListItem sx={{ display: 'list-item' }}>
              Develop Key Skills: Build critical thinking, problem-solving, and research skills
              that are essential for academic success and future careers.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Prepare for University: Enhance university readiness through exposure to
              advanced topics, collaborative learning environments, and engaging projects.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Build Lifelong Friendships: Connect with peers who share similar interests
              and ambitions, creating lasting friendships and a strong support network.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Step Out of Comfort Zones: Learn and practice time management and self-
              discipline, fostering independence and life skills essential for personal
              growth.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Enhance Social Skills: Improve social abilities through daily group activities
              and collaborative experiences, interacting with peers from diverse
              backgrounds around the globe.
            </ListItem>
          </List>
          <Typography variant={isMobile ? 'body2' : 'body1'} >
            Academic summer schools provide a focused, inspiring environment for students
            to grow academically, explore new passions, and gain confidence for the future.
          </Typography>
        </QATemplate>

        <QATemplate question='4. What are the differences between Academic Summer School and Summer Camp?'>
          <ComparisonTable />
        </QATemplate>

        <QATemplate question='5. What service does Unicamp provide?'>
          <Typography variant={isMobile ? 'body2' : 'body1'} >
            Unicamp is a platform that connects you with quality summer schools, study camps,
            and enrichment opportunities worldwide. We’re here to help you discover programs
            that match your interests and needs. You can explore options on your own or work
            with our knowledgeable counsellors for personalized recommendations and
            application support. You have the flexibility to apply directly through the camp’s
            official website using our exclusive promo codes, or let us guide you through the
            entire process—from choosing the right program to submitting your application.
          </Typography>
          <Typography variant={isMobile ? 'body2' : 'body1'} >
            Most importantly, with Unicamp, you’ll enjoy exclusive perks like cashback
            opportunities and early bird bonuses on all programs, making it easier and more
            rewarding.
          </Typography>
        </QATemplate>

        <QATemplate question='6. How do I enrol? Can I enrol through the camp organizer’s official website?'>
          <Typography variant={isMobile ? 'body2' : 'body1'} >
            You have two <Link to='/promo'>
              options
            </Link> to enrol:
          </Typography>
          <List sx={{
            listStyleType: 'disc',
            listStylePosition: 'outside',
            ml: 5
          }}>
            <ListItem sx={{ display: 'list-item' }}>
              Through Unicamp with the support of our friendly counsellors.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Directly through the camp provider’s official website using our exclusive
              Promo Code.
            </ListItem>
          </List>
          <Typography variant={isMobile ? 'body2' : 'body1'} >
            Either way, you’ll enjoy all the benefits offered by the camp provider, along with
            exclusive perks from Unicamp, such as additional cashback offers and premium early
            bird bonuses. Whichever method you select, we’re always here to ensure your
            application experience is pleasant and rewarding.
          </Typography>
          <Typography variant={isMobile ? 'body2' : 'body1'}  sx={{ mt: 2, mb: 5 }}>
            Choose the option that best fits your needs and embark on a seamless journey
            toward your ideal program!
          </Typography>
        </QATemplate>
      </Box>
    </ThemeProvider>
  )
};
  
export default FrequentlyAskedQuestions;
  