import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';


const PromoMobile = () => {
  return (
    <Box sx={{
      height: 'auto',
      width: '80%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginTop: '2%',
      marginBottom: '5%'
    }}>
      <Typography variant='h4'>Ongoing Promotions</Typography>

      <List sx={{
        listStyleType: 'decimal',
        listStylePosition: 'outside',
        ml: '5%',
        fontSize: '1rem'
      }}>
        <ListItem sx={{ display: 'list-item' }}>
          <Typography variant='body2' fontWeight='bold' >
            Early Bird Bonus:
          </Typography>
          <Typography variant='body2' mt={0} >
            <span style={{ fontWeight: 'bold' }}>£500</span> (Apply before 30 Nov 2024)
          </Typography>
          <Typography variant='body2' mt={0} >
            Note: Applies to all Immerse residential programs running in July and August 2025
          </Typography>
        </ListItem>

        <ListItem sx={{ display: 'list-item' }}>
          <Typography variant='body2' fontWeight='bold' >
            Special Bonus:
          </Typography>
          <Typography variant='body2' mt={0} >
            Residential: <span style={{ fontWeight: 'bold' }}>£1400</span>
          </Typography>
          <Typography variant='body2' mt={0} >
            Non-residential: <span style={{ fontWeight: 'bold' }}>£1000</span>
          </Typography>
          <Typography variant='body2' mt={0} >
            Note: Applies only to the Immerse University of Sydney program running in January 2025
          </Typography>
        </ListItem>

        <ListItem sx={{ display: 'list-item' }}>
          <Typography variant='body2' fontWeight='bold' >
            Cashback Reward:
          </Typography>
          <Typography variant='body2' mt={0} >
          <span style={{ fontWeight: 'bold' }}>5%</span> (Promo Code: Unicamp)
          </Typography>
          <Typography variant='body2' mt={0} >
            Note:
          </Typography>
          <List sx={{
            width: '95%',
            listStyleType: 'disc',
            listStylePosition: 'outside',
            ml: 2,
            fontSize: '1rem'
          }}>
            <ListItem sx={{ display: 'list-item', textAlign: 'left' }}>
              This applies to all Immerse programs, including residential, non-residential,
              and online. However, Chinese passport holders studying in mainland China
              are excluded from this reward (see T&amp;Cs for details). Chinese passport
              holders studying outside mainland China remain eligible.
            </ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'left' }}>
              The cashback percentage is calculated based on the actual amount paid after
              applying any eligible bonuses or discounts.
            </ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'left' }}>
              To redeem your cashback reward, please notify us once you have enrolled in
              the program. The cashback will be deposited into your nominated bank
              account within two weeks upon your notification.
            </ListItem>
          </List>
          <Typography variant='body2' mt={0} >
            The cashback reward offers a generous additional benefit on top of any eligible
            bonuses for all Immerse programs.
          </Typography>

          <Typography variant='body2' fontWeight='bold' >How to receive your Bonus and Reward?</Typography>
          <Typography variant='body2' mt={0} >Follow the step-by-step instructions to ensure you receive your bonus and reward.</Typography>
          <List sx={{
            width: '95%',
            listStyleType: 'disc',
            listStylePosition: 'outside',
            ml: '5%',
            fontSize: '1rem'
          }}>
            <ListItem sx={{ display: 'list-item', textAlign: 'left' }}>
              <Link to='/apply/unicamp'
                style={{
                  cursor: 'pointer',
                  color: '#3c95d3'
                }}
              >
                Option 1
              </Link>
              : Apply through us
            </ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'left' }}>
              <Link to='/apply/direct'
                style={{
                  cursor: 'pointer',
                  color: '#3c95d3'
                }}
              >
                Option 2
              </Link>
              : Apply directly on the provider’s website by using our Promo
              Code “<span style={{ fontWeight: 'bold' }}>Unicamp</span>”
            </ListItem>
          </List>

          <Typography variant='body2' fontWeight='bold' >Terms &amp; Conditions:</Typography>
          
          <List sx={{
            listStyleType: 'decimal',
            listStylePosition: 'outside',
            ml: 5,
            fontSize: '1rem',
            textAlign: 'left'
          }}>
            <ListItem sx={{ display: 'list-item' }}>
              If you choose to apply through the provider’s website, you need to inform us as
              soon as your application is lodged. Once we verify your details with the provider,
              we will add you to our client list so you can enjoy the bonus and reward.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Notify us promptly after making the payment and include proof of transaction.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              For Chinese passport holders studying in mainland China, you can apply for all
              Immerse programs to enjoy the bonus and reward, except the one at the
              University of Cambridge. For these applicants, we highly recommend the
              equivalent programs at the University of Oxford.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Unicamp reserves the right to make final decisions and interpretations regarding
              the terms of this promotion package.
            </ListItem>
          </List>
        </ListItem>
      </List>
    </Box>
  )
};

export default PromoMobile;
