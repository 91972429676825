

import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import AboutPhoto1 from '../../assets/AboutPhoto1.jpg';
import AboutPhoto2 from '../../assets/AboutPhoto2.jpg';

const theme = createTheme({
  typography: {
    body1: {
      lineHeight: '2rem',
      marginTop: '2%',
      textAlign: 'justify',
      fontSize: '1rem'
    }
  },
});

const AboutDesktop = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        height: 'auto',
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: '10vw',
        paddingRight: '10vw',
        marginTop: '2%',
        marginBottom: '5%'
      }}>
        <Box sx={{
          height: 'auto',
          width: 'auto',
          maxWidth: '50%',
          textAlign: 'justify'
        }}>
          <Typography variant='h4'>About</Typography>
          <Typography variant='body1' sx={{ mt: 2 }}>
            Welcome to Unicamp, your gateway to world-class campus programs across the globe! 
            We’re here to make finding the perfect academic camp or online enrichment program easy and rewarding. 
            As a platform connecting you with a diverse selection of top-quality camps, we offer the flexibility for you to explore, research, and choose the best fit for your goals.
          </Typography>
          <Typography variant='body1' sx={{ mt: 2 }}>
            Participating in academic camps and online enrichment programs opens a world of possibilities for children.
            These experiences allow them to delve into their interests, shape their academic pathways, and gain firsthand insights into university life or studying abroad.
            Along the way, they’ll build lasting friendships, develop essential leadership skills, and cultivate the confidence needed to pursue their future aspirations.
          </Typography>
          <Typography variant='body1' sx={{ mt: 2 }}>
            With Unicamp, your options are abundant!
            Clients can easily apply directly on our partner websites for their chosen programs using our exclusive promo codes, unlocking a range of exciting benefits.
            Alternatively, our friendly counselors are here to assist you through the consultation and application process, ensuring a seamless experience.
            When you choose Unicamp, you not only gain access to exceptional programs but also enjoy our exclusive perks, including generous cashback, early bird bonuses, and so on!
          </Typography>
        </Box>
        <Box sx={{
          maxWidth: '50%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'space-evenly',
          marginLeft: '10%',
          flex: 1
        }}>
          <img 
            src={AboutPhoto1} 
            alt='Oxford' 
            style={{
              maxWidth: '70%',
              minHeight: 0,
              minWidth: 0,
              height: '35%',
              objectFit: 'cover',
              borderTopLeftRadius: '8vmin'
            }} />
          <img 
            src={AboutPhoto2} 
            alt='University' 
            style={{
              maxWidth: '70%',
              height: '35%',
              objectFit: 'cover',
              borderBottomRightRadius: '8vmin',
              marginTop: '5%'
            }} />
        </Box>
      </Box>
    </ThemeProvider>
  )
};

export default AboutDesktop;
