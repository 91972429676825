import React from 'react';
import { useMediaQuery } from 'react-responsive';

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Fade from '@mui/material/Fade';

import Boston from '../assets/picturesforweb/Boston.jpg';
import Cambridge from '../assets/picturesforweb/Cambridge.jpg';
import London from '../assets/picturesforweb/London.jpg';
import NewYork from '../assets/picturesforweb/NewYork.jpg';
import Online from '../assets/picturesforweb/Online.jpg';
import Oxford from '../assets/picturesforweb/Oxford.jpg';
import SanFrancisco from '../assets/picturesforweb/SanFrancisco.jpg';
import Singapore from '../assets/picturesforweb/Singapore.jpg';
import Sydney from '../assets/picturesforweb/Sydney.jpg';
import Tokyo from '../assets/picturesforweb/Tokyo.jpg';
import Toronto from '../assets/picturesforweb/Toronto.jpg';

const itemData = [
  {
    img: Oxford,
    title: 'Oxford',
    link: 'https://www.immerse.education/oxford-summer-school/'
  },
  {
    img: Cambridge,
    title: 'Cambridge',
    link: 'https://www.immerse.education/cambridge-summer-school/'
  },
  {
    img: London,
    title: 'London',
    link: 'https://www.immerse.education/london-summer-school/'
  },
  {
    img: Sydney,
    title: 'Sydney',
    link: 'https://www.immerse.education/sydney-summer-school/'
  },
  {
    img: Boston,
    title: 'Boston',
    link: 'https://www.immerse.education/boston-summer-school/'
  },
  {
    img: NewYork,
    title: 'New York',
    link: 'https://www.immerse.education/new-york-summer-school/'
  },
  {
    img: SanFrancisco,
    title: 'San Francisco',
    link: 'https://www.immerse.education/san-francisco-summer-school/'
  },
  {
    img: Toronto,
    title: 'Toronto',
    link: 'https://www.immerse.education/toronto-summer-school/'
  },
  {
    img: Singapore,
    title: 'Singapore',
    link: 'https://www.immerse.education/singapore-summer-school/'
  },
  {
    img: Tokyo,
    title: 'Tokyo',
    link: 'https://www.immerse.education/tokyo-summer-school/'
  },
  {
    img: Online,
    title: 'Online Courses',
    link: 'https://www.immerse.education/online-courses/'
  }
];


const HomeGallery = () => {
  const [hoverIndex, setHoverIndex] = React.useState(null);
  const [cols, setCols] = React.useState(3);

  const isDesktop = useMediaQuery({ query: '(min-width: 1224px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  React.useEffect(() => {
    if (isDesktop) {
      setCols(4);
    } else if (isMobile) {
      setCols(1);
    } else {
      setCols(3);
    }
  }, [isDesktop, isMobile]);

  return (
    <ImageList sx={{ width: '50vw', height: '50vh', marginTop: '5vh' }} style={{ gap: '10px' }} cols={cols} rowHeight={'auto'}>
      {itemData.map((item, index) => (
        <a key={item.img} href={item.link} >
          <ImageListItem
            onMouseOver={() => setHoverIndex(index)}
            onMouseOut={() => setHoverIndex(null)}
            style={{
              height: '100%'
            }}
          >
            <img
              srcSet={`${item.img}?w=164&fit=fill&auto=format&dpr=2 2x`}
              src={`${item.img}?w=164&fit=fill&auto=format`}
              alt={item.title}
              loading="lazy"
              style={{
                borderRadius: '10px',
                height: '10vh',
                transition: 'opacity 1s',
                opacity: hoverIndex === index ? '100%' : '80%',
                objectFit: 'cover'
              }}
            />
            <ImageListItemBar
              title={item.title}
              position="top"
              sx={{ borderRadius: '10px' }}
              actionIcon={
                <Fade in={hoverIndex === index}>
                  <DoubleArrowIcon sx={{ marginRight: '10px', color: 'white'}}/>
                </Fade>
              }
            />
          </ImageListItem>
        </a>
      ))}
    </ImageList>
  )
};

export default HomeGallery;
