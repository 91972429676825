import { useMediaQuery } from 'react-responsive';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import PromoMobile from '../components/responsiveness/PromoMobile';
import PromoDesktop from '../components/responsiveness/PromoDesktop';


const theme = createTheme({
  typography: {
    body1: {
      lineHeight: '2rem',
      marginTop: '2%',
      textAlign: 'justify',
      fontSize: '1rem'
    },
    body2: {
      lineHeight: '2rem',
      marginTop: '10%',
      textAlign: 'left',
      fontSize: '1rem'
    }
  },
});

const Promo = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  return (
    <ThemeProvider theme={theme}>
      {
        isMobile
        ?
        <PromoMobile />
        :
        <PromoDesktop />
      }
    </ThemeProvider>
  )
};

export default Promo;
