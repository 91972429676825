import { useMediaQuery } from 'react-responsive';

import AboutDesktop from '../components/responsiveness/AboutDesktop';
import AboutMobile from '../components/responsiveness/AboutMobile';

const About = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  return (
    <>
      {
        isMobile &&
        <AboutMobile />
      }
      {
        !isMobile &&
        <AboutDesktop />
      }
    </>
  )
};

export default About;
  