
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';

const ContactSubmitted = () => {
  return (
    <Box sx={{
      height: '100%',
      width: '100%',
      backgroundColor: '#bc945d',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5%'
    }}>
      <Box sx={{
        height: 'auto',
        width: 'auto',
        backgroundColor: 'white',
        borderRadius: '1vmin',
        padding: '10%',
        textAlign: 'center'
      }}>
        <Typography>
          We appreciate you reaching out and will get in touch with you as soon as possible.
        </Typography>
      </Box>
    </Box>
  )
};

export default ContactSubmitted;
